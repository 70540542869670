// @font-face {
//   font-family: ""Poppins"";
//   src: url("./Assets/fonts/"poppins"-regular.ttf") format("ttf");
// }

// body, html{
//   font-family: ""Poppins"";
// }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Poppins" !important;
}
.MuiButton-root {
  border-radius: 50px !important;
}
.section-intro-heading {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  color: #177ff0;
  font-size: x-large;
}
.section-first-heading {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  color: #177ff0;
  font-size: x-large;
}
.section-main-text {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}
.line {
  height: 5px;
  width: 125px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #1480f3;
  // box-shadow: 10px 9px 18px 1px rgba(23, 127, 240, 0.75);
  // -webkit-box-shadow: 10px 9px 18px 1px rgba(23, 127, 240, 0.75);
  // -moz-box-shadow: 10px 9px 18px 1px rgba(23, 127, 240, 0.75);
}

.section-heading {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.section-paragraph {
  font-family: "Poppins" !important;
  opacity: 1 !important;
}

//Small mobiles
@media only screen and (min-width: 320px) and (max-width: 424px) {
  .section-heading {
    font-size: 18px !important;
  }
  .section-paragraph {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .line {
    height: 5px;
    width: 60px;
    margin-top: 6px;
  }
}

//modern mobiles
@media only screen and (min-width: 425px) and (max-width: 600px) {
  .section-heading {
    font-size: 18px !important;
  }
  .section-paragraph {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  .line {
    height: 5px;
    width: 60px;
    margin-top: 4px;
  }
}

//Tablets
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .section-heading {
    font-size: 18px !important;
  }
  .section-paragraph {
    font-size: 14px !important;
  }

  .line {
    height: 5px;
    width: 60px;
    margin-top: 4px;
  }
}

//Laptops
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .section-heading {
    font-size: 20px !important;
  }
  .section-paragraph {
    font-size: 16px !important;
  }

  .line {
    height: 5px;
    width: 60px;
    margin-top: 4px;
  }
}

//Desktops
@media only screen and (min-width: 1200px) and (max-width: 1536px) {
  .section-heading {
    font-size: 22px !important;
  }
  .section-paragraph {
    font-size: 18px !important;
  }

  .line {
    height: 5px;
    width: 100px;
    margin-top: 8px;
  }
}

//Large screens
@media only screen and (min-width: 1536px) {
  .section-heading {
    font-size: 36px !important;
  }
  .section-paragraph {
    font-size: 20px !important;
  }
}
