.model-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
}
.model-card {
  width: 200px;
  height: 200px;
  border: 1px solid #1480f3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.model-image {
  height: 90px;
  width: 90px;
}
.model-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  text-align: center;
  white-space: pre-wrap;
}

@media (min-width: 320px) and (max-width: 767px) {
  .model-image {
    height: 90px;
    width: 90px;
  }
  .model-card {
    width: 200px;
    height: 200px;
    margin-top: 20px;
    border: 1px solid #1480f3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .model-text {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .model-details-container {
    max-width: 90% !important;
  }
}

@media (min-width: 1440px)  {
  .model-details-container {
    max-width: 80% !important;
  }
}

@media (min-width: 2560px)  {
  .model-details-container {
    max-width: 60% !important;
  }
}