.choose-us-section-container {
  // margin: 3rem auto auto;
}
.choose-us-heading {
  color: #1480f3;
}
.choose-us-content {
  text-align: justify;
  font-family: "Poppins";
}
.content{
  padding-top: 50px;
  text-align: left;
}
.number {
  font-size: 5rem;
  font-family: "Poppins";
}
.choose-us-line {
  border-top: 1px solid #1480f3;
}
.who-are-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;

  text-align: center;
  color: #000000;
  width: 252px;
  height: 63px;
}
.who-are-para1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  /* or 183% */
  width: 478px;
  height: 77px;
  letter-spacing: -0.01em;
  color: #000000;
}
.who-are-para2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  letter-spacing: 0.01em;
  color: #000000;
  opacity: 0.5;
  // width: 700px;
}
.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 25px;
  gap: 10px;
  width: 160.15px;
  height: 55px;
  background: #1480f3;
  color:#FFFFFF;
  border:none;
  border-radius: 10px;
}
.dots-div{
  display: flex;
  justify-content: space-between;
  
}


@media (min-width: 320px) and (max-width: 767px) {
  .content{
    padding-top: 50px;
  }
  .who-are-heading {
    text-align: center !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #000000;
    width: auto;
    height: 63px;
}
  .who-are-para1 {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 35px !important;
    /* or 183% */
    margin-top: -10px;
    height: auto;
    width: auto;
    letter-spacing: -0.01em;
    color: #000000;
  }
  .who-are-para2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    text-align: justify !important;
    letter-spacing: 0.01em;
    color: #000000;
    opacity: 0.5;
    width: 100%;
  }
  .who-are-para2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
    letter-spacing: normal;
    color: #000000;
    opacity: 0.5;
    width: 100%;
}
  .dots-div{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .about-us{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px !important;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}
.who-we-are-fluid-container{
  margin-left: 0px !important;
  margin-right: 0px !important;
}


}
@media (min-width:768px) and (max-width:1023px){
  .child-container-text {
    box-sizing: border-box;
    
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    /* margin-top: -40px; */
    width: calc(100% ) !important; 
     margin-left: 0px !important; 
    margin-top: 16px;
  }
  .who-are-heading {
    text-align: center !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    color: #000000;
    width: auto;
    height: 63px;
}
.who-are-para1 {
  text-align: center;
 
  font-weight: 400;
  font-size: 19px;
  line-height: 35px !important;
  width: auto !important;
 
}
.who-are-para2{
  padding-left: 50px;
  padding-right: 50px;
  font-size: 16px;
}
.dots-div{
  padding-left: 50px;
  padding-right: 50px;
}
}


@media (min-width: 1024px) and (max-width: 1439px) {

  .who-are-para1 {
    padding-right: 50px;
   
  }
  .who-are-para2{
   
    padding-right: 50px;
  }
  .dots-div{
    
    padding-right: 50px;
  }
}

