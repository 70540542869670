.techstack-section-container {
  max-width: 100%;
  margin: 5rem auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-image {
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  width: auto;
  height: auto;
}
.award-image1 {
  height: 150px;
  width: 150px;
  margin-left: 15px;
  margin-right: 15px;
}

.element-container {
  overflow-x: scroll;
  display: flex;
}

.stack-heading-text {
  color: #010101;
  font-weight: 700;
  font-family: "Poppins";
  font-size: 24px !important;
}

.css-19kzrtu {
  padding: 0 !important;
}

.stack-description-text {
  color: #010101;
  font-size: 16px !important;
  font-family: "Poppins" !important;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
}
.tag-list1 {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  // gap: 30px;
  position: relative;
  padding: 1.5rem 0;
  
}

.loop-slider {
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
}
@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


.tech-stack-tab-heading {
  transition: 0.3s ease-out !important;
  text-decoration: none !important;
  -webkit-box-shadow: 22px 22px 50px -30px rgba(23, 127, 240, 0.5) !important;
  box-shadow: 30px -40px 50px -30px rgba(23, 127, 240, 0.5) !important;
}

.MuiTabs-indicator {
  border-radius: 50px 50px 0px 0px !important;
}

.tech-stack-icon-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  // width: 80%;
  padding: 10px 10px;
  // border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.tech-stack-image-item-grid{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.center-aligned-images{
  flex-direction: row;
  justify-content: center;
}



@media screen and (max-width: 600px) {

  .techstack-section-container {
    max-width: 90%;
    margin: 2rem auto auto;
  }

  .stack-description-text {
    font-size: 12px !important;
  }
}


@media (min-width:320px )and (max-width:767px)
{
  .heading-container p{
    font-size: 26px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width:768px )and (max-width:1023px)
{
  .heading-container p{
    font-size: 35px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}