.main {
  background-color: #1d1d1d;
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
  margin-top: 60px;
}
.content {
  padding-top: 70px;
}
.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  text-align: center;
}
.para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}
.container {
  margin-top: 30px;
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.card {
  border: #ffffff;
  border-radius: 10px;
// width: 80%;
  height: 150px;
}

.card-header {
  display: flex;
  background-color: #ffffff;
  padding: 10px;
  min-height: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #1480f3;
  border-bottom: none;
}

.card-body {
  padding-left: 60px;
  padding-top: 0px;
  padding-right: 10px;
  text-align: left;
}

@media (min-width: 320px) and (max-width: 767px) {
  .content {
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }
  .card-body {
    padding-left: 50px;
    padding-top: 0px;
    // padding-right: 10px;
    padding-bottom: 10px;
    text-align: left;
    margin-top: -23px;
  }
  .card {
    border: #ffffff;
    border-radius: 10px;
  // width: 80%;
 
    height: 136px;
  }
  .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    text-align: center;
}
.para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}
.card-header p{
  font-size: 15px;
}
.card-body p{
  font-size: 12px;
  padding-left: -10px;
  
}
.why-choose-us-heading-and-para{
  justify-content: center;
}
.card-header img{
  height:35px !important;
}

}


@media(min-width:768px ) and (max-width:1023px)
{
  .para{
    font-size: 19px;
  }
  .heading{
    font-size: 35px;
  }
  .card-header{
    padding-bottom: 0px !important;
  }
  .card-body p {
    font-size: 14px;
    margin-top: -5px;
}
  
}


@media (min-width:1024px) and (max-width:2560px)
{
  .card-body p{
    font-size: 14px;
  } 
  .card-header p{
    font-size: 15px;
  }
  .card-header img{
    height:35px !important;
  }
  .card-body {
    padding-left: 50px;
  }
  .card-header{
    padding-bottom: 0px !important;
  }
  .card-body p {
    font-size: 14px;
    margin-top: -10px;
}
  
}