.footer-box-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // height: 225px;
  // width: 225px;
  height: 259px;
  width: 259px;
  border: 1px solid #1480f3;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
}
.footer-box-heading-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 130% */

  text-align: center;
  letter-spacing: -0.01em;

  color: #ffffff;
  margin: 25px 0px 5px 0px;
}
.footer-box-primary-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
}

.footer-box-link-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 160% */
  color: #1480f3;
  margin: 20px 0px 5px 0px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer-box-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 249px;
    width: 184px;
    border: 1px solid #1480f3;
    border-radius: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
  }
  .footer-box-primary-text {
    font-size: 12px;
  }
  .footer-box-heading-text {
    font-size: 15px !important;
  }
 
  .footer-box-image{

    width:30px;

  }
  
}
