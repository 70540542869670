.award-container {
  padding-top: 60px;
  
  overflow: hidden;
}
.award-image {
  height: 160px;
  width: 160px;
  margin-left: 15px;
  margin-right: 15px;
}
.tag-list {
  width: 99vw;
  max-width: 99vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 30px;
  position: relative;
  padding: 1.5rem 0;
  overflow: hidden;
}

.loop-slider {
  .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #334155;
  border-radius: 0.4rem;
  padding: 0.7rem 1rem;
  margin-right: 1rem; // Must used margin-right instead of gap for the loop to be smooth
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 20%), 0 0.1rem 0.5rem rgb(0 0 0 / 30%),
    0 0.2rem 1.5rem rgb(0 0 0 / 40%);
}

.fade {
  pointer-events: none;
  background: linear-gradient(
    90deg,
    #1e293b,
    transparent 30%,
    transparent 70%,
    #1e293b
  );
  position: absolute;
  inset: 0;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (min-width:320px) and (max-width:767px)
{
  .heading-text {
    display: flex;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px !important;
    line-height: 36px !important;
    margin-bottom: 20px;
}
.description-text {
  font-family: "Poppins";
  font-size: 12px;
  text-align: center;
}
.description-text {
  font-family: Poppins;
  font-size: 12px !important;
  text-align: center;
}
.award-image {
  height: 145px;
  width: 145px;
  margin-left: 15px;
  margin-right: 15px;
}
.award-container {
  padding-top: 60px;
  padding-bottom: 0px;
  overflow: hidden;
}

}

@media(min-width:767px ) and (max-width:1023px)
{
  .heading-text {
    display: flex;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 35px !important;
    line-height: 63px;
}
.description-text{
  font-size: 19px;
}

}

@media (min-width:1024px) and (max-width:2560px)
{
  .description-text{
    width:100% !important;
  }
}