.services-section-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
 justify-content: center;
 margin-top: -110px;
}
.service-slogan {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  opacity: 1 !important;
  text-align: center;
}
.service-card {
  display: block;
  height:100%;
  position: relative;
  background-color: #ffffff;
  // border-radius: 4px;
  // padding: 32px 24px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;

  transition: all 0.3s ease-out;
  text-decoration: none;
  // -webkit-box-shadow: 22px 22px 50px -30px rgba(23, 127, 240, 0.5);
  // box-shadow: 10px -30px 50px -10px rgba(200, 206, 212, 0.5);
  box-shadow: 3px 23px 67px -21px rgba(0,0,0,0.5);
  -webkit-box-shadow: 3px 23px 67px -21px rgba(0,0,0,0.5);
  -moz-box-shadow: 3px 23px 67px -21px rgba(0,0,0,0.5);
  // &:before {
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   bottom: -16px;
  //   right: -16px;
  //   // #171d4a #1480f3
  //   // background: transparent linear-gradient(45deg, #1480f3 0%, #1480F3 0%) 0%
  //   //   0% no-repeat padding-box;
  //   height: 32px;
  //   width: 32px;
  //   border-radius: 32px;
  //   transform: scale(1);
  //   transform-origin: 50% 50%;
  //   transition: transform 0.5s ease-out;
  // }

  // &:hover:before {
  //   transform: scale(45);
  //   background: #1480F3;
  // }
  // .selected{
  //   transform: scale(45);
  //   background: #1480F3;
  // }
}
// .card-logo{
//   position: absolute;
//   top: 350px;
//   left: 172px;
// }
// .css-18lsnm6-MuiGrid-root>.MuiGrid-item {
//   padding-left: 100px !important;
// }

.service-card-heading,
.service-card-description,
.sub-heading {
  font-family: "Poppins";
}

.learn-more{
  font-size: 15px;
  font-weight: 500;
  color: #1480F3;
  position:relative;
 
}

.service-card-description {
  color: #696969;
  text-justify: auto;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}

.service-card-heading {
  color: #000000;
  font-weight: bold !important;
  text-align: start;
  padding-left: 14px;
}
.service-card:hover {
  // .learn-more{
  //   color: #FFFFFF
  // }
  // p {
  //   transition: all 0.3s ease-out;
  //   color: #FFFFFF
  // }
  // h3 {
  //   transition: all 0.3s ease-out;
  //   color: #ffffff;
  // }
}

// .go-corner {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   width: 70px;
//   height: 50px;
//   overflow: hidden;
//   bottom: 0;
//   right: 0;
//   background-color: #1480f3;
//   border-radius: 32px 0 0 0;
// }

// .go-arrow {
//   // margin-top: -4px;
//   margin-right: -4px;
//   color: white;
//   font-family: "Poppins", sans;
// }
@media (min-width:320px ) and (max-width:374px)
{
  .services-section-container {
    max-width: 90%;
    margin: 2rem auto auto;
    margin-top: -70px !important;
}
.service-card-heading {
  color: #000000;
  font-weight: bold !important;
  text-align: start;
  padding-left: 0px;
  width:200px;
}
.service-card {
  padding: 30px 32px !important;
}
}

@media (min-width:375px ) and (max-width:424px)
{
  .services-section-container {
    max-width: 90%;
    margin: 2rem auto auto;
    margin-top: -60px !important;
}
.service-card-heading {
  color: #000000;
  font-weight: bold !important;
  text-align: start;
  padding-left: 0px;
  width:200px;
}
.service-card {
  padding: 30px 32px !important;
}
}


@media (min-width:425px ) and (max-width:767px)
{
  .services-section-container {
    max-width: 90%;
    margin: 2rem auto auto;
    margin-top: -60px !important;
}
.service-card-heading {
  color: #000000;
  font-weight: bold !important;
  text-align: start;
  padding-left: 0px;
  width:200px
}
.service-card {
  padding: 30px 32px !important;
}
.service-card-description{
  font-size: 15px !important;
  
}
}










@media (min-width:768px) and (max-width: 1023px) {
  .service-card-image-container{
    display: flex;
    justify-content: center;
  }
  .service-card-text-container{
    width: 85%;
    margin-top: 15px !important;
   
  }
  .service-card-heading {
    color: #000000;
    font-weight: bold !important;
    text-align: start;
    padding-left: 0px;
    font-size: 16px !important;
  }
  .services-section-container {
    max-width: 100%;
    margin: 2rem auto auto;
    margin-top: -57px;
}
  .service-card-description {
    color: #696969;
    text-justify: auto;
    text-align: left;
    padding-left: 0px !important;
    margin-left: 0px !important;
    padding-right: 1rem;
    padding-top: 0.5rem;
    margin-top: 15px !important;
    width:400px;
  }
  .service-card{
    padding: 7px 18px !important;
  }
  .service-card-description{
    width:200px !important;
    font-size: 13px !important;
    padding-top: 0px !important;
  }
  
}

@media(min-width:1024px) and (max-width:1439px)
{
  .service-card-heading {
 
    font-size: 17px !important;
  }
  .service-card-description{
    width:200px !important;
    font-size: 15px !important;
    
  }
}

@media(min-width:1440px) and (max-width:2559px)
{
  .service-card-heading {
 
    font-size: 18px !important;
  }
  .service-card-description{
    width:200px !important;
    font-size: 14px !important;
    
  }
}