.header-container {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-carousel-container {
  max-width: 100vw;
}

.slider-control-centerright,
.slider-control-centerleft {
  display: none;
}

.header-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 25%;
  // left: 44%;
  // top: 65%;
}

.menu-container {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.header-image-content {
  z-index: 1;
}

.hover-service-container1,
.hover-service-container2,
.hover-service-container3,
.hover-service-container4 {
  // height: 100vh !important;
  min-height: 80vh !important;
  max-width: 100% !important;
  min-width: 100% !important;
  z-index: 1;

  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  text-align: center;
  justify-content: space-around;
}

.hover-service-container1 {
  color: white !important;
  background: linear-gradient(#807e7e80, rgba(107, 105, 105, 0.4)),
    url("../../Assets/images/Mask_group.png") !important;
  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url("../../Assets/images/banner_bg_4.jpg") !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 100% 100% !important;
}

.hover-service-container2 {
  color: white !important;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../../Assets/images/banner_bg_6.jpg") !important;
  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url("../../Assets/images/banner_bg_2.jpg") !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 100% 100% !important;
}

.hover-service-container3 {
  color: white !important;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../../Assets/images/banner_bg_7.jpg") !important;
  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url("../../Assets/images/banner_bg_3.jpg") !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 100% 100% !important;
}

// .hover-service-container4 {
//   color: white !important;
//   background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
//     url("https://res.cloudinary.com/dtju0fkhl/image/upload/v1666787047/banner_3_muedcf.jpg") !important; /* Chrome 10-25, Safari 5.1-6 */
//   // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url("../../Assets/images/banner_bg_1.jpg") !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
//   background-size: 100% 100% !important;
// }
.btn-services {
  width: 220px !important;
  height: 67.58px !important;
  background: #1480f3 !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  border: none;
}

.button-vector {
  padding-left: 6px;
  font-family: "Poppins" !important;
}

// .btn-services:hover {
//   transform: scale(1.1);
// }

.header-content-description {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}

.header-content-heading-h4 {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
}

.header-content-heading-h1 {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
}

.dots {
  align-self: center;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.dots li {
  margin-right: 6px;
}

.dots li:before {
  content: "";
  height: 12px;
  width: 12px;
  display: inline-block;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.dots li.active:before {
  width: 30px;
  background: #1480f3;
}

.new-dots {
  content: "";
  height: 12px;
  width: 12px;
  display: inline-block;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

@media (min-width: 320px) and (max-width: 374px) {
  .header-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8%;
    margin-top: 40px;
  }
  .blue-btn-with-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px !important;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}
}
@media (min-width: 375px) and (max-width: 424px) {
  .header-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8%;
    margin-top: 40px;
  }
  .blue-btn-with-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px !important;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}
}
@media (min-width: 425px) and (max-width: 767px) {
  .header-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8%;
    margin-top: 40px;
  }
  .blue-btn-with-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px !important;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}

}
@media (min-width: 768px) and (max-width: 1023px) {
  .header-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8%;
    margin-top: 40px;
  }
  .blue-btn-with-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px !important;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
}
.header-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 23% !important;
  margin-top: 40px;
}

}

@media (min-width: 320px) and (max-width: 767px) {
  .main-carousel-container {
    max-height: 289px !important;
    // height: auto !important;
  }

  video {
    display: none;
  }

  .header-container {
    position: relative;
    box-sizing: border-box;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        0deg,
        rgba(34, 33, 33, 0.9),
        rgba(34, 33, 33, 0.9)
      ),
      url("../../Assets/images/main-banner-bg.jpg");
  }

  .header-text-container {
    margin-top: 85px;
  }

  .first-text {
    font-size: 24px !important;
    font-weight: 600 !important;
    margin-left: 15px;
    margin-right: 15px;
  }

  .second-text {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 190px;
    text-align: center;
    font-size: 17px !important;
  }

  .hover-service-container1,
  .hover-service-container2,
  .hover-service-container3,
  .hover-service-container4 {
    min-height: 289px !important;
  }

  .dots li:before {
    height: 8px;
    width: 8px;
  }

  .hover-service-container1 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
      url("https://res.cloudinary.com/dtju0fkhl/image/upload/v1666787047/banner_1_hudag3.jpg")
        no-repeat !important;
    background-size: 100% 289px !important;
  }

  .hover-service-container2 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
      url("https://res.cloudinary.com/dtju0fkhl/image/upload/v1666787047/banner_2_xu0jif.jpg")
        no-repeat !important;
    background-size: 100% 289px !important;
  }

  // .hover-service-container2 {
  // }
  .hover-service-container3 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
      url("https://res.cloudinary.com/dtju0fkhl/image/upload/v1666787047/banner_4_uxvupm.jpg")
        no-repeat !important;
    background-size: 100% 289px !important;
  }

  .hover-service-container4 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
      url("https://res.cloudinary.com/dtju0fkhl/image/upload/v1666787047/banner_3_muedcf.jpg")
        no-repeat !important;
    background-size: 100% 289px !important;
  }
  .header-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 25%;
    margin-top: 40px;
}
}
