.footer-section-container {
  max-width: 80%;
  margin: auto;
  margin-top: 4rem;
}

.row {
  --bs-gutter-x: 0rem;
}
.footer-logo-img {
  width: 281px;
  height: 66px;
}
.main-footer-container {
  // background: #000000;
  background: url("../../Assets/images/footer-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.footer-child-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}




.social-circle-icons li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.text-small {
  font-size: 0.9rem;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.small-font {
  font-size: 0.88rem;
  color: #ffffff;
}

.large-font {
  font-size: 1.6rem;
}

.form-control {
  background: #ffffff;
  border-color: #545454;
}

.form-control:focus {
  background: #212529;
}
.secondary-footer {
  background: #1480f3;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.icons {
  justify-content: center;
  align-items: center;
}

.silicon-nexus-footer-message {
  font-family: "Poppins" !important;
  font-size: 15px;
  text-justify: auto;
  text-align: justify;
}

.footer-text-heading {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
}
.hr-line-footer-heading {
  width: 60px;
  height: 2px;
  opacity: 1;
  margin-top: -10px;
  background-color: white;
}

.footer-item-text {
  font-family: "Poppins";
  font-size: 15px;
  text-justify: auto;
  text-align: justify;
}

.subscribe-btn {
  background: #1480f3 0% 0% no-repeat padding-box;
  border: 2px solid #1480f3;
  opacity: 1;
  color: #ffffff;
  font-weight: 600;
}

.subscribe-field {
  background: #fefefe 0% 0% no-repeat;
  border: 2px solid #fefefe;
  opacity: 1;
}

.subscribe-field:focus {
  background: #fefefe 0% 0% no-repeat;
  border: 2px solid #fefefe;
  opacity: 1;
  box-shadow: none;
}

.footer-item-text li {
  padding-top: 0.5rem;
}
.underline-text {
  text-decoration: none;
  color: #ffffff;
}
.footer-nav-link {
  font-family: "Poppins";
  font-size: 16px;
  margin-top: 5px !important;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  // transition: font-weight 0.25s;
}

.footer-nav-link:hover {
  font-weight: 600;
  color: #1480f3;
}
.social-icon {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  color: #ffffff;
}

.social-icon:hover {
  transform: translateY(-2px);
}

.social-icon:nth-child(1) i:hover {
  color: #3b5999;
}

.social-icon:nth-child(2) i:hover {
  color: #55acee;
}

.social-icon:nth-child(4) i:hover {
  color: #e95950;
}

.social-icon:nth-child(3) i:hover {
  color: #0072b1;
}

.copy-right-text {
  font-family: "Poppins";
  color: #ffffff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  margin-bottom: 12px !important;
  margin-left: -9px !important;
}

@media (min-width:320px) and (max-width:374px)
{
  .css-mjlngd-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 12px!important;
    margin-top: 15px !important;
}
.img-responsive{
  margin-left: -10px;
}
.custom-upper-padding {
  padding-top: -21px !important;
  margin-top: -16px !important;
}
.blue-btn-with-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 25px;
  gap: 10px;
  background: #1480f3;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  /* width: 151px; */
}
.main-footer-container{
  padding-top: 24px;
  margin-top: -145px !important;
}


}

@media(max-width:425px)
{
  .footer-child-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 210px;
  }
  .blue-btn-with-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 25px;
    gap: 10px;
    background: #1480f3;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    /* width: 151px; */
  }
  .main-footer-container{
    padding-top: 24px;
  margin-top: -145px !important;
  }
  
}

@media(min-width:768px)
{
  .footer-child-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  
}

@media(min-width:1024px)
{
  .footer-child-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 60px;
    padding-right: 60px;
  }
  
  
}

@media(min-width:2560px)
{
  .footer-section-container {
    max-width: 45%;
    margin: auto;
    margin-top: 4rem;
}
}