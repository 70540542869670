.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.heading-text {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
}

.description-text {
  font-family: "Poppins";
  // font-style: normal;
  // font-weight: 200;
  font-size: 24px;
  // line-height: 36px;
  text-align: center;
}


@media (min-width:768px) and (max-width:1023px) {
  .description-text {
    font-family: "Poppins";
    // font-style: normal;
    // font-weight: 200;
    font-size: 14px;
    // line-height: 36px;
    text-align: center;
    width: 100%;
  }
}

@media (min-width:1024px) and (max-width:1439px) {
  .description-text {
    font-family: "Poppins";
    // font-style: normal;
    // font-weight: 200;
    font-size: 20px;
    // line-height: 36px;
    text-align: center;
    width: 100%;
  }
}

@media (min-width:1440px) {
  .description-text {
    font-family: "Poppins";
    // font-style: normal;
    // font-weight: 200;
    font-size: 24px;
    // line-height: 36px;
    text-align: center;
    width: 100%;
  }
}