.menu-info-heading {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
}

.menu-info-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}

.menu {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 2;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 3s ease;

  &.active {
    left: 0;
    width: 75vw;
  }
}

.right-box {
  height: 100vh;
  width: 22vw;
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
  opacity: 0;
  display: flex;
  overflow: hidden;
  background-image: url("../../Assets/images/right001.png");
  background-repeat: no-repeat;
  background-size: cover;
  &.active {
    right: 0;
    height: 100vh;
    opacity: 1;
    position: fixed;
    overflow-y: hidden;
    transition: transform 3s ease;
  }
  img {
    height: 48px;
    width: 48px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 15px;
    margin-right: 80px;
    text-align: center;
  }
  p {
    position: absolute;
    right: 0;
    left: 50px;
    bottom: 0px;
    padding-bottom: 10%;
    color: #ffffff;
    text-align: center;
  }
  h3 {
    position: absolute;
    text-align: center;
    writing-mode: vertical-lr;
    transform: scale(-1);
    color: #ffffff;
    top: 0;
    bottom: 0;
    right: 0;
    margin-right: 40px;
    font-size: 48px;
    font-weight: 800;
    display: block;
  }

  .icon-section {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8rem;
    align-self: baseline;
    margin-right: 40px;
    font-size: 40px;
    font-weight: 800;
    // background-color: #177ff0;
    top: 39%;
  }
}
.left-icon-section {
  position: absolute;
  bottom: 20px;
  left: 0;
  display: none;
}
.left-box {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.logo-container {
  position: absolute;
  left: 0;
  top: 0;
}
.sales-container {
  position: absolute;
  display: flex;
  flex-direction: row;

  img {
    height: 64px;
    width: 64px;
    margin: 0 20px;
  }
}

.close-button {
  height: 48px;
  width: 48px;
  color: #177ff0;
  position: fixed;
  top: 50%;
  bottom: 50%;
  right: 20.6vw;
  display: flex;
  background-color: #fff;
  z-index: 2;
  opacity: 0;
  &.active {
    right: 22.5vw;
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    transform: rotate(40deg);
    transition: all 0.3s ease-in-out 0s;
    background-color: #000000;
    // border-radius: 24px;
    // z-index: -1 !important;
  }

  .close-button-image {
    height: 48px;
    width: 48px;
    background-image: url("../../Assets/images/right007.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    &:hover {
      height: 48px;
      width: 48px;
      background-image: url("../../Assets/images/right006.png");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }
  }
}

.menu-media-icons li {
  list-style: none;
}

.menu-media-icons li a .icon {
  position: relative;
  color: #1480f3;
  transition: 0.5s;
  z-index: 3;
  // background-color: #ffffff;
  text-align: center;
  font-size: large;
  font-weight: bold;
  vertical-align: middle;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 25px;
  // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #000000;
}

.menu-media-icons li:hover .icon {
  transform: scale(1.1);
  filter: drop-shadow(8px 8px 4px #1480f3);
}

.menu-media-icons li:nth-child(1) a:hover .icon {
  color: #3b5999;
}

.menu-media-icons li:nth-child(2) a:hover .icon {
  color: #55acee;
}

.menu-media-icons li:nth-child(3) a:hover .icon {
  color: #0077b5;
}

.menu-media-icons li:nth-child(4) a:hover .icon {
  color: #dd4b39;
}

//Social Icons section ends

// .list-container {
//   display: inline-flex;
//   cursor: pointer;
// }

// .list-container .list {
//   font-size: 60px;
//   font-weight: 600;
//   color: #000000;
//   margin: 10px 20px;
//   font-family: "Poppins";
// }
// .list-container .list:hover {
//   color: #177ff0;
// }
// .categories-list {
//   list-style: none;
//   opacity: 1;
// }
// .list-container .hover-box {
//   position: absolute;
//   top: 30%;
//   left: 10%;
//   width: auto;
//   z-index: 1;
//   // background: #fff;
//   color: #000;
//   // padding: 10px 18px;
//   font-size: 152px;
//   font-weight: 800;
//   opacity: 0;
//   pointer-events: none;
//   transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   -webkit-text-fill-color: white; /* Will override color (regardless of order) */
//   -webkit-text-stroke-width: 0.5px;
//   -webkit-text-stroke-color: #000;
// }

// .list-container .icon:hover .hover-box {
//   top: -70px;
//   opacity: 1;
//   pointer-events: auto;
// }
// .list-container .home:hover .hover-box,
// .list-container .home:hover .hover-box:before {
//   opacity: 1;
//   position: absolute;
//   top: 20%;
//   left: 10%;
//   white-space: nowrap;
// }
// .list-container .about:hover .hover-box,
// .list-container .about:hover .hover-box:before {
//   opacity: 1;
//   position: absolute;
//   top: 20%;
//   left: 10%;
//   white-space: nowrap;
// }
// .list-container .services:hover .hover-box,
// .list-container .services:hover .hover-box:before {
//   opacity: 1;
//   position: absolute;
//   top: 20%;
//   left: 10%;
//   white-space: nowrap;
// }
// .list-container .blogs:hover .hover-box,
// .list-container .blogs:hover .hover-box:before {
//   opacity: 1;
//   position: absolute;
//   top: 20%;
//   left: 10%;
//   white-space: nowrap;
// }
// .list-container .portfolio:hover span,
// .list-container .portfolio:hover .hover-box,
// .list-container .portfolio:hover .hover-box:before {
//   opacity: 1;
//   position: absolute;
//   top: 20%;
//   left: 10%;
//   white-space: nowrap;
// }

.follow-heading {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  display: none;
  position: absolute;
  bottom: 80px;
  left: 20px;
  color: #000;
}

// Social Icons
.social-circle-icons li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #ffffff;
}

.social-icon {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  color: #177ff0;
  height: 45px;
  width: 45px;
  border-radius: 25px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.social-icon:hover {
  transform: translateY(-2px);
}

.social-icon:nth-child(1) i:hover {
  color: #3b5999;
}

.social-icon:nth-child(2) i:hover {
  color: #55acee;
}

.social-icon:nth-child(4) i:hover {
  color: #e95950;
}

.social-icon:nth-child(3) i:hover {
  color: #0072b1;
}

@media (min-width:320px ){

.list-unstyled{
  margin-left: 0px !important;
}
  
}

@media (min-width:320px )and (max-width:425px){
  .social-icon {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    color: #177ff0;
    height: 35px;
    width: 35px;
    border-radius: 25px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    
}
.list-unstyled{
  margin-left: 0px !important;
}
  
}
@media only screen and (max-width: 600px) {
  .logo-container {
    margin-top: 20px;
    margin-left: 20px;
    img {
      height: 40px;
    }
  }

  .nav-items-container-grid {
    margin-top: 2rem;
  }
  // .list-container .list {
  //   font-size: 16px;
  // }
  .hover-box {
    display: none;
  }
  .info-container .menu-info-heading {
    font-size: 16px;
    color: #000;
  }
  .follow-heading {
    display: block;
  }

  .right-box .icon-section {
    display: none;
  }
  .left-icon-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .menu-media-icons li {
    display: inline-block;
    // margin-left: 10px;
    margin-right: 10px;
  }
  .menu-media-icons li a .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 20px;
  }

  .sales-container {
    bottom: 125px;
    left: 0;
    img {
      height: 48px;
      width: 48px;
    }
    p {
      font-size: 12px;
    }
  }

  // .list-container {
  //   display: block;
  //   cursor: pointer;
  // }
  .left-box {
  }
  .right-box {
    width: 37.5vw;
    background: linear-gradient(
      129.44deg,
      #1480f3 -10.41%,
      #1556ab 75.83%,
      #171d4a 149.29%
    );

    img {
      display: none;
    }
    p {
      display: none;
    }
    h3 {
      display: none;
    }
  }

  .close-button {
    right: 19vw;
    &.active {
      right: 19vw;
      opacity: 1;
    }
  }
}

// @media only screen and (min-width: 425px) and (max-width: 600px) {
//   .logo-container {
//     img {
//       height: 40px;
//     }
//   }
//   .nav-items-container-grid {
//     margin-top: 2rem;
//   }
//   .list-container .list {
//     font-size: 16px;
//   }
//   .hover-box {
//     display: none;
//   }
//   .info-container .menu-info-heading {
//     font-size: 20px;
//     color: #000;
//   }
//   .right-box .icon-section {
//     right: -0.1rem;
//     top: 74%;
//   }

//   .menu-media-icons li {
//     margin-top: -1rem;
//   }
//   .menu-media-icons li a .icon {
//     height: 40px;
//     width: 40px;
//     line-height: 40px;
//     border-radius: 20px;
//   }

//   .sales-container {
//     bottom: 0;
//     img {
//       height: 48px;
//       width: 48px;
//     }
//     p {
//       font-size: 16px;
//     }
//   }

//   .list-container {
//     display: block;
//     cursor: pointer;
//   }
//   .left-box {
//     height: 125%;
//   }
//   .right-box {
//     width: 37.5vw;
//     &.active {
//       height: 100%;
//       overflow-y: hidden;
//     }
//     img {
//       display: none;
//     }
//     p {
//       display: none;
//     }
//     h3 {
//       display: none;
//     }
//   }

//   .close-button {
//     right: 19vw;
//     &.active {
//       right: 19vw;
//       opacity: 1;
//     }
//   }
// }
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .logo-container {
    margin-top: 40px;
    margin-left: 40px;
    img {
      height: 50px;
    }
  }
  .sales-container {
    // margin-top: 120px;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 20px;
    right: 0;
    top: 0;
    img {
      display: none;
    }
    p {
      font-size: 20px;
    }
  }

  // .list-container {
  //   display: block;
  //   cursor: pointer;
  // }
  .left-box {
    height: 125%;
    width: 77.5vw;
  }
  .right-box {
    width: 37.5vw;
    &.active {
      height: 100%;
      overflow-y: hidden;
    }
    img {
      display: none;
    }
    p {
      font-size: 14px;
      left: 110px;
      right: 0;
      text-align: center;
    }
    h3 {
      font-size: 32px;
    }
  }
  .close-button {
    right: 30vw;
    &.active {
      right: 30vw;
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .logo-container {
    margin-top: 40px;
    margin-left: 40px;
    img {
      height: 50px;
    }
  }
  .info-container .menu-info-heading {
    font-size: 20px;
    color: #000;
  }
  .info-container .menu-info-text {
    font-size: 16px;
  }
  // .list-container .list {
  //   font-size: 20px;
  // }
  .info-container .menu-info-heading {
    font-size: 24px;
    color: #000;
  }
  .info-container .menu-info-text {
    font-size: 16px;
  }
  // .list-container .hover-box {
  //   font-size: 60px;
  // }

  .right-box .icon-section {
    right: 3rem;
    font-size: 30px;
    font-weight: 500;
    top: 38%;
  }

  .menu-media-icons li a .icon {
    height: 40px;
    width: 40px;
    font-size: medium;
    line-height: 40px;
    border-radius: 20px;
  }

  .sales-container {
    // margin-top: 120px;
    right: 0;
    top: 0;
    margin-top: 40px;
    margin-right: 40px;
    img {
      display: none;
    }
    p {
      font-size: 20px;
    }
  }

  // .list-container {
  //   display: block;
  //   cursor: pointer;
  // }
  .left-box {
    height: 125%;
    width: 77.5vw;
  }
  .right-box {
    width: 37.5vw;
    &.active {
      height: 100%;
      overflow-y: hidden;
    }
    img {
      display: none;
    }
    p {
      font-size: 14px;
      left: 150px;
      right: 0;
      text-align: center;
    }
    h3 {
      font-size: 32px;
    }
  }
  .close-button {
    right: 21vw;
    &.active {
      right: 19.5vw;
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .logo-container {
    margin-top: 40px;
    margin-left: 40px;
    img {
      height: 50px;
    }
  }
  .left-box {
    width: 80vw;
    height: 105%;
  }

  // .list-container .list {
  //   font-size: 50px;
  // }
  .info-container .menu-info-heading {
    font-size: 24px;
    color: #000;
  }
  .info-container .menu-info-text {
    font-size: 16px;
  }
  // .list-container .hover-box {
  //   font-size: 120px;
  // }
  .sales-container {
    right: 0;
    top: 0;
    margin-top: 40px;
    margin-right: 40px;
  }
  .close-button {
    right: 19vw;
    &.active {
      right: 18.5vw;
    }
  }
  .right-box {
    width: 22.5;
    &.active {
      height: 105%;
      overflow-y: hidden;
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1536px) {
  .logo-container {
    margin-top: 40px;
    margin-left: 40px;
    img {
      height: 50px;
    }
  }
  // .list-container .list {
  //   font-size: 30px;
  // }
  // .list-container .hover-box {
  //   font-size: 100px;
  // }

  .right-box .icon-section {
    right: 8rem;
    font-size: 30px;
    font-weight: 500;
    top: 37.5%;
  }

  .menu-media-icons li {
    margin-top: 0.5rem;
  }
  .info-container .menu-info-heading {
    font-size: 24px;
    color: #000;
  }

  .menu-media-icons li a .icon {
    height: 50px;
    width: 50px;
    font-size: medium;
    line-height: 50px;
    border-radius: 25px;
  }
  .sales-container {
    right: 0;
    top: 0;
    margin-top: 40px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1536px) and (max-width: 2560px) {
  .logo-container {
    margin-top: 40px;
    margin-left: 40px;
  }
  .left-box {
    width: 80vw;
    height: 105%;
  }

  // .list-container .list {
  //   font-size: 40px;
  // }
  .info-container .menu-info-heading {
    font-size: 24px;
    color: #000;
  }
  .info-container .menu-info-text {
    font-size: 16px;
  }
  // .list-container .hover-box {
  //   font-size: 100px;
  // }

  .close-button {
    right: 17.7vw;
    &.active {
      right: 17.7vw;
    }
  }
  .right-box {
    width: 22.5;
    &.active {
      height: 105%;
      overflow-y: hidden;
    }
  }
  .sales-container {
    right: 0;
    top: 0;
    margin-top: 40px;
    margin-right: 40px;
  }
  .right-box .icon-section {
    right: 8rem;
    font-size: 30px;
    font-weight: 500;
    top: 40%;
  }

  .menu-media-icons li {
    margin-top: 0.5rem;
  }

  .menu-media-icons li a .icon {
    height: 50px;
    width: 50px;
    font-size: large;
    line-height: 50px;
    border-radius: 25px;
  }
}

@media only screen and (min-width: 2560px) {
  .left-box {
    width: 85.5vw;
    height: 105%;
  }

  // .list-container .list {
  //   font-size: 50px;
  // }
  .info-container .menu-info-heading {
    font-size: 24px;
    color: #000;
  }
  .info-container .menu-info-text {
    font-size: 16px;
  }
  // .list-container .hover-box {
  //   font-size: 152px;
  // }

  .right-box {
    background-image: url("../../Assets/images/right008.png");

    &.active {
      width: 15vw;
      height: 105%;
      overflow-y: hidden;
    }
    p {
      margin-bottom: 100px;
    }
  }
  .close-button {
    right: 13.5vw;
    &.active {
      right: 13.5vw;
    }
  }
}

// New Menu CSS
@media only screen and (max-width: 600px) {
  .new-menu > ol > .new-menu-item > .hover-box:hover {
    display: none;
  }
}
.new-menu {
  position: relative;
  padding: 30px 0;
  font-family: "Poppins";
  @media only screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
  }
  > ol {
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    > .new-menu-item {
      display: inline-block;
      position: relative;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      .hover-box {
        position: absolute;
        top: 30%;
        left: 10%;
        width: auto;
        z-index: 1;
        // background: #fff;
        color: #000;
        // padding: 10px 18px;
        font-size: 80px;
        font-weight: 500;
        opacity: 0;
        pointer-events: none;
        transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
      }
      &:hover {
        .hover-box {
          opacity: 1;
          position: absolute;
          top: -275%;
          left: 10%;
          white-space: nowrap;
          // color: #000;
        }
        .sub-new-menu {
          opacity: 1;
          list-style: none;
          .new-menu-item {
            @for $i from 1 through 6 {
              &:nth-child(#{$i}) {
                animation-name: subNewMenuAnimation;
                animation-duration: 300ms;
                animation-delay: (100ms * $i);
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;
              }
            }
          }
        }
      }
      > .link-item {
        color: #000;
        text-decoration: none;
        font-family: "Poppins";
        font-size: 2.5vw;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0 60px;
        display: block;
        @media only screen and (max-width: 600px) {
          margin: 5px 0;
          font-size: 16px;
          width: 200px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1200px) {
          margin: 20px;
          font-size: 18px;
        }
        // @media only screen and (max-width: 800px) {
        //   margin: 30px 0;
        //   font-size: 30px;
        // }
        &:hover {
          color: #1480f3;
          font-weight: 600;
        }
      }
      .sub-new-menu {
        padding: 28px 25px 0;
        position: absolute;
        left: 0;
        opacity: 0;
        list-style: none;
        @media only screen and (max-width: 600px) {
          display: none;
        }
        @media only screen and (max-width: 768px) {
          left: 100%;
          top: -32px;
        }
        .new-menu-item {
          opacity: 0;
          .link-new-menu-item {
            color: #000;
            text-decoration: none;
            font-size: 1vw;
            font-weight: 400;
            font-family: "Poppins";
            letter-spacing: 1px;
            margin: 12px 40px;
            display: block;
            white-space: nowrap;
            text-transform: uppercase;
            transition: color 0.3s cubic-bezier(0.13, 1.07, 0.84, 1);
            @media only screen and (max-width: 1080px) {
              font-size: 20px;
            }
            &:hover {
              color: #1480f3;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@keyframes subNewMenuAnimation {
  0% {
    opacity: 0;
    transform: translateX(-30px);
    //filter: blur(5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
