.engagement-card-section-container {
  max-width: 80%;
  margin: auto;
  margin-top: 2rem;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.model-details-container {
  max-width: 80% ;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;

  border: 1px solid rgba(20, 128, 243, 0.1);
  margin-top: -20px;
  background-color: #1480f3;
  border-radius: 20px;
}

.pricing-btn:hover{
  background-color: #1480f3 !important;
  color: #ffffff;
}
.model-detail-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}

.curved-svg {
  stroke: #ffffff;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 2px 6px;
  animation: animateline 5s linear both infinite;
  fill: none;
  // animation: firstAnimation 2s linear forwards infinite;
}
.model-details-direction-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  bottom: -40px;
}
@keyframes firstAnimation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -10rem;
  }
}

//Direction arrow styles
svg line {
  stroke: #ffffff;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 2px 6px;
  animation: animateline 5s linear both infinite;
}

@-moz-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -15rem;
  }
}

@-webkit-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -15rem;
  }
}

@-o-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -15rem;
  }
}

@keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -15rem;
  }
}

.model-detail-step {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.engagement-card-heading,
.engagement-card-description {
  font-family: "Poppins";
  font-weight: 600 !important;
}
.engagement-card-description {
  color: #000000;
  text-justify: auto;
  text-align: justify;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.engagement-card-heading {
  color: #000000;
  font-weight: 600;
  text-align: center;
  margin-top: 40px;
}

.engagement-card--first,
.engagement-card--second {
  display: flex;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  -webkit-box-shadow: 22px 22px 50px -30px rgba(23, 127, 240, 0.5);
  box-shadow: 30px -40px 50px -30px rgba(23, 127, 240, 0.5);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0px;
    right: 0px;
    transition: all 0.5s ease-out;
    background: transparent linear-gradient(45deg, #1480f3 0%, #171d4a 100%) 0%
      0% no-repeat padding-box;
    transform-origin: 60% 60%;
  }
}

.engagement-card--third {
  display: flex;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  // -webkit-box-shadow: 22px 22px 50px -30px rgba(23, 127, 240, 0.5);
  // box-shadow: 30px -40px 50px -30px rgba(23, 127, 240, 0.5);

  box-shadow: -12px -29px 50px -30px rgba(23, 127, 240, 0.75);
  -webkit-box-shadow: -12px -29px 50px -30px rgba(23, 127, 240, 0.75);
  -moz-box-shadow: -12px -29px 50px -30px rgba(23, 127, 240, 0.75);

  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0px;
    right: 0px;
    transition: all 0.5s ease-out;
    background: transparent linear-gradient(45deg, #1480f3 0%, #171d4a 100%) 0%
      0% no-repeat padding-box;
    transform-origin: 60% 60%;
  }
}
.engagement-card--first:hover,
.engagement-card--second:hover,
.engagement-card--third:hover {
  &:hover:before {
    transform: scale(45);
    height: 20px;
    width: 20px;
    border-radius: 32px;
  }

  .engagement-card-heading {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}

.view-pricing-btn {
  color: #ffffff !important;
  border: 0.800000011920929px solid #1480f3 !important;
  // border-radius: 0 !important;
  font-size: 18px;
  font-weight: 700 !important;
  padding: 0.5rem !important;
  background-color: #1480f3 !important;
}
.view-pricing-btn:hover {
  background-color: #ffffff !important;
  color: #1480f3 !important;
}

.arrow-up {
  width: 0px;
  height: 0px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #d9d9d9;
}

.arrow-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .engagement-card-section-container {
    max-width: 90%;
    margin: 2rem auto auto;
  }
}

@media screen and (max-width: 800px) {
  .hide-engagement-mobile-container {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .hide-engagement-web-container {
    display: none;
  }
}
