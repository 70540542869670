html, body {
  width: 100%;
  margin: 0; 
  padding: 0; 
  overflow-x: hidden;
}
.blog-link {
  color: blue;
}

.contact-us-section-container {
  max-width: 1200px;
}
h1,
p {
  letter-spacing: 0;
}
.blog-details-section-container,
.blog-filters-container,
.blog-page-section-container,
.careers-page-section-container,
.contact-us-page-section-container,
.portfolio-section-container,
.services-section-container,
.staff-augmentation-section-container {
  width: 80%;
  margin: 2rem auto auto;
  // z-index: -1;
}
.staff-augmentation-section-container {
  padding-bottom: 119px;
}
.offer-text {
  color: #19284e;
  text-align: justify;
  font-size: 24px;
}
.form-control:focus {
  background: #fff !important;
  color: #000;
}
.what-we-offer-heading {
  font: var(--unnamed-font-style-normal) normal bold 42px/63px
    var(--unnamed-font-family-poppins);
  color: var(--unnamed-color-010101);
  text-align: left;
  font: bold 42px/63px Poppins;
  letter-spacing: 0;
  color: #010101;
  opacity: 1;
}
.blog-filter-background-container {
  background: url("../Assets/images/blog_filter_background.jpg") 0 0/100% 100%;
  height: fit-content;
  padding-bottom: 2rem;
}
.blog-filter-heading-text {
  font-size: 40px;
  font-family: "Poppins";
  letter-spacing: 8px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 1;
}
.blog-heading-text {
  font-size: 24px !important;
  font-family: "Poppins" !important;
  color: #0b0b0b !important;
  font-weight: 550 !important;
}
.lets-talk-btn,
h1,
p {
  font-family: "Poppins";
}
.arrow-symbol-readmore,
.blog-readmore-btn {
  color: #010101 !important;
}
.blog-card-container {
  background: var(--unnamed-color-ffffff) 0 0 no-repeat padding-box;
  border: 2px solid #ededed;
  padding: 1rem;
  padding-top: 2rem;
}
.blog-readmore-btn,
.loadmore-blogs-btn {
  background: no-repeat padding-box #fff !important;
  font-family: "Poppins" !important;
}
.blog-readmore-btn {
  border: 0.30000001192092896px solid #707070 !important;
  // border-radius: 0 !important;
  padding: 1rem !important;
}
.blog-readmore-btn:hover,
.loadmore-blogs-btn:hover {
  border: none;
  color: #1480f3 !important;
  border-color: #1480f3 !important;
}
.arrow-symbol,
.blog-readmore-btn:hover .arrow-symbol-readmore,
.loadmore-blogs-btn:hover .arrow-symbol-readmore {
  color: #1480f3 !important;
}
.load-more-blogs-container {
  text-align: center;
  border: none;
}
.loadmore-blogs-btn {
  border: 0.30000001192092896px solid #707070 !important;
  // border-radius: 0 !important;
  padding: 1rem 2rem !important;
  color: #010101 !important;
}
.filter-form-container,
.keywords-filter-field {
  background-color: #fff !important;
  padding-bottom: 0.5rem !important;
}
.filter-form-container {
  // border-radius: 0 !important;
  border: none !important;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  background: #fff !important;
}
.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-radius: 0px !important;
}
.css-o69gx8-MuiCardMedia-root {
  width: 428px;
  height: 289px;
  object-fit: contain !important;
}

.search-blogs-btn,
.search-blogs-btn:hover {
  font-weight: 600 !important;
  padding-bottom: 1rem !important;
}
.search-blogs-btn {
  border: 1px solid #fff !important;
  border-radius: 0% !important;
  color: #fff !important;
}

.arrow-symbol-search-blogs,
.lets-talk-btn:hover,
.lets-talk-btn:hover .arrow-symbol {
  color: #fff !important;
}

.lets-talk-btn {
  color: #1480f3 !important;
  border: 0.800000011920929px solid #1480f3 !important;
  // border-radius: 0 !important;
  font-size: 18px;
  font-weight: 700 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.lets-talk-btn:hover {
  background-color: #1480f3 !important;
}
.heading-container {
  display: flex;
  padding-top: 30px;
  box-sizing: border-box;
  flex-direction: column;
}
h1 {
  font-size: 42px;
  font-weight: 600;
}
p {
  font-size: 24px;
  color: #00000080;
}

.menu-container {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.offer-text {
  text-align: justify !important;
}

.tag-section-heading {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
// padding-bottom: 2px;
  color: #1480f3;
  padding-left: 8px;
 
}
.tag-box-title{
  border-left: 5px solid #1480f3;
  border-radius: 3px;
}
.show-blog-detail {
  width: 50vw;
  position: relative;
  text-align: justify;
}
.blogdetai-image{
  width: 839px;
height: 532px;

}
.blogdetai-image img{
  width: 100%; /* Make the image fill the container horizontally */
  height: 100%; /* Make the image fill the container vertically */
  object-fit: cover; 
border-radius: 10px;
}


.card-left {
  width: 305px;
  height: 270px;
  position: relative;
  border-radius:15px ;
}

.card-left::before {
  content: "";
  position: absolute;
 left: 0;
        bottom: 0;
        height: 150px;
        width: 50%;
        border-left: 15px solid #1480f3;
        border-radius: 2px;
}
.card-left::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  // transform: translateX(-50%);
  width: 50%;
  height: 15px;
  background-color: #1480f3;
  border-radius: 2px;
}
.card-blog-image {
  width: 261px;
  height: 261px;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.card-blog-image-background {
  width: 115px;
  height: 111px;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  bottom: -10px;
  left: -10px;
  z-index: 0;
}
.filter-card-blog-image-001 {
  width: 194.5px;
   height: 209px;
  box-sizing: border-box;
  border: 2px solid #1480f3;
  border-radius: 6px;
  object-fit: cover;
  position: absolute;
  bottom:-17px;
  right: 235px;
  z-index: -1;
  
}
.filter-card-blog-side-image-002{
  width: 5px;
height: 24px;
background: #1480F3;
border-radius: 4px;
position: absolute;
right: 620px;
bottom:210px;
}
.recent-card-blog-image-00{
  width: 194.5px;
 height: 500.5px;
position: absolute;
border: 2px solid #1480F3;
border-radius: 6px;
bottom:-600px;
z-index: -1;
}
.recent-card-blog-side-image-000{
  width: 5px;
  height: 24px;
  background: #1480F3;
  border-radius: 4px;
  position: absolute;
  right: 620px;
  bottom:-100px;
}
.tag-card-blog-image-11{
  width: 194.5px;
// height: 209px;
  box-sizing: border-box;
  border: 2px solid #1480f3;
  border-radius: 6px;
  position: absolute;
  bottom:-930px;
  right: 235px;
   z-index: -1;
  
}
.tag-card-blog-side-image-112{
  width: 5px;
  height: 24px;
  background: #1480F3;
  border-radius: 4px;
  position: absolute;
  right: 620px;
  bottom:-800px;
}
.card-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}
.card-blog-title-1 {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}
.card-blog-desc-1 {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.card-blog-bottom {
  background: rgba(20, 128, 243, 0.04);
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}
.card-blog-bottom-left {
  color: rgb(82, 81, 81);
}
.card-blog-bottom-right {
  color: #1480f3;
}
//recent blog

.recent-blog-image {
  width: 79px;
  height: 79px;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}

.recent-post-blog-container {
  border-bottom: 1px solid lightgray;

  //hei: 40px;
}

.recent-post-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding-right: 0.5px;
  color: #000000;
  text-align: left;
  
}
.recent-post-date-text {
  // padding-top: 30px;
  padding-right: 100px;
}

.section-first-heading {
  font-family: "Poppins";
  font-size: 25px;
  line-height: 27px;
  color: #1480f3;
  text-align: left;
  padding-top: 2%;
  padding-left: 5%;
  font-weight: bold;
}
.section-first-heading-title{
 
}
.job-post-heading{
  color: #1480F3;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 500;
}
.blog-detail-title {
  // font-weight: 700px;
  color: black;
  font-weight: bold;
  font-size: 30px;
  text-align: left;
  // text-transform: uppercase;
}
.tech-name-cu {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.start-date {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
  .mt-3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
}
.blog-card {
  width: 1000px;
  // padding: 20px 20px 20px 0;
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  // margin-top: 40px;
  border-bottom: 1px solid lightgray;
  padding-top: 30px;
}
.blog-card-container1 {
  width: 20vw;
  font-size: 20px;
  margin-top: 3rem;
  position: relative;
}

.blog-card-container1::before {
  content: "";
  position: absolute;
  right: 0;
        bottom: 0;
        height: 180px;
        width: 50%;
        border-right: 3px solid #1480f3;
        border-radius: 2px;
}
.blog-card-container1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  // transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #1480f3;
  border-radius: 2px;
}
.recent-blog-card-container2 {
  width: 20vw;
  // font-size: 20px;
  margin-top: 3rem;
  position: relative;
}
.recent-blog-card-container2::before {
  content: "";
  position: absolute;
  right: 0;
        bottom: 0;
        height: 400px;
        width: 50%;
        border-right: 3px solid #1480f3;
        border-radius: 2px;
}
.recent-blog-card-container2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  // transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #1480f3;
  border-radius: 2px;
}
.blog-card-container1 select {
  border: 2px solid #ededed;
}
.blog-card-container1 #textt {
  // margin-top: 0px;
  padding-bottom: 5px;
  border: 2px solid #ededed;
}
#categorycard{
  width: 20vw;
  margin-top: 3rem;
  position: relative;
}
#categorycard::before {
  content: "";
  position: absolute;
  right: 0;
        bottom: 0;
        height: 180px;
        width: 50%;
        border-right: 3px solid #1480f3;
        border-radius: 2px;
}
#categorycard::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  // transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #1480f3;
  border-radius: 2px;
}


#tagcard {
  width: 20vw;
  margin-top: 3rem;
  position: relative;
}
#tagcard::before {
  content: "";
  position: absolute;
  right: 0;
        bottom: 0;
        height: 180px;
        width: 50%;
        border-right: 3px solid #1480f3;
        border-radius: 2px;
}
#tagcard::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  // transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #1480f3;
  border-radius: 2px;
}
.blog-detail-date{
  color: black;
}

.blog-tags{
  font-size: 20px;
  font-weight: 500;
}
.category-box{
  display: inline-block;
  padding: 8px 16px;
  margin: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  cursor: pointer;
width: 18vw;
height: 7vh;
border-radius: 3px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
padding-top: 22px;
  // filter: opacity(0.5) drop-shadow(0 0 brown);
text-align: left;
position: relative;
}
.category-box:hover {
  background-color: rgb(0, 119, 255);
}

.category-box:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 132, 255, 0.7); /* blue with 50% opacity */
}
.blogdetail-category-box{
  display: inline-block;
  padding: 8px 16px;
  margin: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  cursor: pointer;
width: 18vw;
height: 7vh;
border-radius: 3px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
padding-top: 22px;
  // filter: opacity(0.5) drop-shadow(0 0 brown);
text-align: left;
position: relative;
}
.catcard {
  width: 20vw;
  margin-top: 3rem;
}
.container {
  //margin-left: 18vw;
  //margin-right: 5vw;
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
}

.tag-box {
  display: inline-block;
  background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.16);
border-radius: 4px;
  border-radius: 10px;
  padding: 6px 12px;
  margin: 4px;
  font-size: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  //font-weight: bold;
  //text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: fit-content; /* Adjust the width based on content */
  white-space: nowrap; /* Prevent line breaks within a tag box */
}

.tag-box:hover {
  background-color: #e0e0e0;
}


.filter-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  text-align: left;
  color: #1480f3;
  // padding-top: 3%;
  padding-left: 5%;
}
.filter-text-title{
 
  border-left: 5px solid #1480f3;
  border-radius: 3px;
  height: 18px;
}
.blog-detail-page-section-container {
  text-align: left;
}


//Responsive
@media (min-width: 768px) and (max-width: 1024px) {

  .blog-card {
    width: 800px;
    // padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    // margin-top: 40px;
    border-bottom: 1px solid lightgray;
    padding-top: 30px;
  }
  .blog-card-container1 {
    width: 20vw;
    font-size: 20px;
    margin-top: 3rem;
    position: relative;
  }
  
  .blog-card-container1::before {
    content: "";
    position: absolute;
    right: 0;
          bottom: 0;
          height: 180px;
          width: 50%;
          border-right: 3px solid #1480f3;
          border-radius: 2px;
  }
  .blog-card-container1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    // transform: translateX(-50%);
    width: 50%;
    height: 3px;
    background-color: #1480f3;
    border-radius: 2px;
  }
  .recent-blog-card-container2 {
    width: 20vw;
    // font-size: 20px;
    margin-top: 3rem;
    position: relative;
  }
  .recent-blog-card-container2::before {
    content: "";
    position: absolute;
    right: 0;
          bottom: 0;
          height: 320px;
          width: 50%;
          border-right: 3px solid #1480f3;
          border-radius: 2px;
  }
  .recent-blog-card-container2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    // transform: translateX(-50%);
    width: 50%;
    height: 3px;
    background-color: #1480f3;
    border-radius: 2px;
  }
  .blog-card-container1 select {
    border: 2px solid #ededed;
  }
  .blog-card-container1 #textt {
    // margin-top: 0px;
    padding-bottom: 5px;
    border: 2px solid #ededed;
  }
  
  .card-blog-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }
  .card-blog-image-background {
    width: 100px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
    position: absolute;
    bottom: 85px;
    left: -10px;
    z-index: 0;
  }

  .card-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }


}



.category-box.selected {
  border: 2px solid #177FF0;
}

.category-box.selected .text {
  color: #177FF0;
}

.personal-info{
  font-weight: bold;
  word-spacing: 1px;
  margin-top: 5px;
  font-family: 'Poppins';
}

.Technical-Info{
  margin-top: 20px;
  font-weight: bold;
  word-spacing: 1px;
  font-family: 'Poppins';
}
.uploadcv{
 
  //  font-size: 20px;
    // font-weight: bolder;
    word-spacing: 1px;
    font-family: 'Poppins';
    border: 1px solid rgb(197, 195, 195);
    height: 50px;
    align-items: center;
    padding-top: 8px;
    padding-left: 2px;
}
// .sub-btn{
//   padding-left: 550px;
// }

#newscard{
  width: 20vw;
  margin-top: 3rem;
  position: relative;
  height: 50vh;
}
.news-section-heading {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
// padding-bottom: 2px;
  color: #1480f3;
  padding-left: 8px;
 
}
.hiring-section-heading{
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}
.news-card{
  width: 15vw;
  
}
.job-heading{
  background: linear-gradient(
    0deg,
    rgba(39, 39, 39, 0.9),
    rgba(37, 37, 37, 0.9)
  ),
  url("../../src/Pages/hiringjobs.png");
background-size: 100% 100%;
  background-size: 100% 100%;
  color: #ededed;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add your loader styles here */
}

.blur {
  filter: blur(1px);
}
.success-message{
  background-color: #7bb7f8;
  width: 20vw;
  height: 5vh;
  border: 1px solid #7bb7f8;
  border-radius: 10px solid #7bb7f8;
  font-family: "Poppins" !important;
}


.news-box-title{
  border-left: 5px solid #1480f3;
  border-radius: 3px;
}


.job-table-row{
border-bottom: 1px solid gray;
}
.job-post-title{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  text-align: left;
  color: #1480f3;
 
}
.recent-job-post{
  color: #e0e0e0;
  background: linear-gradient(
    0deg,
    rgba(39, 39, 39, 0.9),
    rgba(37, 37, 37, 0.9)
  ),
  url("../../src/Pages/hiringjobs.png");
background-size: 100% 100%;
  background-size: 100% 100%;
  // margin-bottom: 9px;
  border-radius: 6px;
padding-top: 10px;
padding-left: 10px;
height: 102px;

}
.applyFor{
  margin-top: 30px;
//  font-size: 20px;
   font-weight: bold;
  word-spacing: 1px;
  font-family: 'Poppins';
}
.section-heading{
  font-family: 'Poppins';
}
.default{
  color:#000;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins';
}
.news-card{
  width: 18vw;
  border-bottom: 1px solid rgb(243, 226, 226); 
}


.spinner {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-top-color: white;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// @media (min-width: 320px) and (max-width: 374px) {
//   MuiBreadcrumbs-separator {
//     color: #ffffff;
//     font-size: 18px;
//     position: relative;
//     top: 2px;
//   }
//   .container-interested-in {
//     display: flex;
//     flex-direction: row;
//   }
//   .col-12 {
//     flex: 0 0 auto;
//     flex-direction: row;
//     width: 40% !important;
//     margin-left: 18px;
//   }
//   .staff-augmentation-paragraph-our-expertise {
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//   }
//   .staff-augmentation-text-heading {
//     font-size: 18px !important;
//     line-height: 38px !important;
//   }
//   .img-responsive {
//     //display: none;
//     object-fit: fill;
//     position: relative;
//     bottom: -6px;
//     left: -6px;
//     z-index: 0;
//     width: 120px;
//     margin-left: 5px;
//   }
//   .image-responsive-z-index {
//     width: 260px !important;
//   }
//   .custom-adjustment {
//     left: -12px !important;
//   }
//   .selection-text-card {
//     font-size: 0.8rem !important;
//   }
// }

// @media (min-width: 375px) and (max-width: 424px) {
//   MuiBreadcrumbs-separator {
//     color: #ffffff;
//     font-size: 18px;
//     position: relative;
//     top: 2px;
//   }
//   .container-interested-in {
//     display: flex;
//     flex-direction: row;
//   }
//   .col-12 {
//     flex: 0 0 auto;
//     flex-direction: row;
//     width: 45% !important;
//     margin-left: 12px;
//   }
//   .staff-augmentation-paragraph-our-expertise {
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//   }
//   .staff-augmentation-text-heading {
//     font-size: 18px !important;
//     line-height: 38px !important;
//   }
//   .img-responsive {
//     //display: none;
//     object-fit: fill;
//     position: relative;
//     bottom: -6px;
//     left: -6px;
//     z-index: 0;
//     width: 120px;
//     margin-left: 5px;
//   }

//   .image-responsive-z-index {
//     width: 280px !important;
//     left: -18px;
//   }
//   .custom-adjustment-towards-right {
//     left: 5px;
//   }
// }

// @media (min-width: 425px) and (max-width: 767px) {
//   .MuiBreadcrumbs-separator {
//     color: #ffffff;
//     font-size: 18px;
//     position: relative;
//     top: 2px;
//   }
//   .container-interested-in {
//     display: flex;
//     flex-direction: row;
//   }
//   .col-12 {
//     flex: 0 0 auto;
//     flex-direction: row;
//     width: 45% !important;
//     margin-left: 12px;
//   }
//   .staff-augmentation-paragraph-our-expertise {
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//   }
//   .staff-augmentation-text-heading {
//     font-size: 18px !important;
//     line-height: 38px !important;
//   }
//   .img-responsive {
//     object-fit: fill;
//     position: relative;
//     bottom: -6px;
//     left: -6px;
//     z-index: 0;
//     height: 100px;
//   }
//   .image-responsive-z-index {
//     width: 320px !important;
//     margin-left: -55px;
//   }
//   .custom-adjustment-towards-right {
//     left: 56px;
//   }
// }

// @media screen and (max-width: 767px) {
//   .blog-details-section-container,
//   .blog-filters-container,
//   .blog-page-section-container,
//   .careers-page-section-container,
//   .contact-us-page-section-container,
//   .portfolio-section-container,
//   .services-section-container,
//   .staff-augmentation-section-container {
//     width: 100%;
//     margin: 2rem auto auto;
//   }
//   .filter-form-container,
//   .keywords-filter-field {
//     padding-bottom: 0rem !important;
//     padding-top: 0rem !important;
//   }

//   .blog-heading-text {
//     font-size: 18px !important;
//   }
//   .sub-btn {
//     width: 150px !important;
//     height: 50px !important;
//   }
//   .interested-in {
//     font-size: 26px !important;
//   }
// }

// @media (min-width: 768px) and (max-width: 1023px) {
//   .interested-in {
//     font-size: 35px !important;
//   }
//   .staff-augmentation-paragraph-our-expertise {
//     padding-left: 10% !important;
//     padding-right: 10% !important;
//   }
//   .center-for-768px {
//     justify-content: center !important;
//   }
// }
// @media (min-width: 1024px) and (max-width: 2560px) {
//   .interested-in {
//     font-size: 42px !important;
//   }
// }

// @media screen and (min-width: 600px) and (max-width: 900px) {
//   .blog-heading-text {
//     font-size: 18px !important;
//   }
// }

// @media screen and (min-width: 900px) and (max-width: 1200px) {
//   .blog-heading-text {
//     font-size: 18px !important;
//   }
// }

// @media (min-width: 768px) {
//   .staff-augmentation-paragraph-our-expertise {
//     padding-left: 10% !important;
//     padding-right: 10% !important;
//   }
// }

