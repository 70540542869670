.engagement-model-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../../Assets/images/engagement_model.png");
  padding-top: 40px;
  padding-bottom: 60px;
  height: auto;
}
.engagement-model-main-container{
  height: 940px;
  margin-top: 60px;
}

.engagement-model-grid-container{
  display: flex;
  justify-content: center;
}

.engagement-model-paragraph{
  
  margin-left: 30px;
  margin-right: 30px;
}


@media (min-width: 320px) and (max-width: 768px) {
  .engagement-model-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url("../../Assets/images/engagement_model.png");
    padding-top: 40px;
    padding-bottom: 60px;
    height: 100%;
    background-size: 100% 100%;
  }
  .engagement-model-main-container{
    height: 100%;
    margin-top: 60px;
  }
  .engagement-model-heading{
    font-size: 26px !important;
  }
  .engagement-model-paragraph{
    font-size: 17px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
  .model-container {
   
    margin-top: 50px !important;
}
}

@media (min-width: 768px) and (max-width: 1023px) {
  .engagement-model-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url("../../Assets/images/engagement_model.png");
    padding-top: 40px;
    padding-bottom: 60px;
    height: 100%;
    background-size: 100% 100%;
  }
  .engagement-model-main-container{
    height: 100%;
    margin-top: 60px;
  }
  .engagement-model-heading{
    font-size: 35px !important;
  }
  .engagement-model-paragraph{
    font-size: 19px !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  .model-container {
   
    margin-top: 50px !important;
}
}
@media (min-width:1024px) and (max-width:1439px)
{
  .engagement-model-paragraph{
    width:100%;
    margin-left: 0px !important;
    padding-left: 80px;
    padding-right: 80px;
  }
  
}
@media (min-width:1440px) and (max-width:2559px)
{
  .engagement-model-paragraph{
    width:65%;
    margin-left: 244px !important;
  }
}