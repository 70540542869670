.current-link,
.parent-link {
  font-size: 16px;
  font-family: "Poppins";
}
.arrow-btn,
.current-link,
.parent-link {
  font-family: "Poppins";
}
.btn-submit,
.btn-submit:hover {
  background-color: #1480f3;
  color: #fff;
}
.arrow-btn:hover,
.arrow-btn:hover .arrow-symbol {
  color: #fff !important;
}
.parent-link {
  color: #ffffff !important;
}
.current-link {
  color: #1480f3;
}
.arrow-btn,
.arrow-symbol {
  color: #010101 !important;
}
.arrow-btn {
  border: 0.800000011920929px solid #070707 !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.arrow-btn:hover {
  background-color: #1480f3 !important;
  transform: scaleX(1.1);
  border: 0.800000011920929px solid #fff !important;
}

@media only screen and (max-width: 600px) {
  .parent-link {
    font-size: 10px;
  }
  .current-link {
    font-size: 10px !important;
    margin-top: 2px !important ;
  }
}

// color separator
.MuiBreadcrumbs-separator {
  color: #ffffff;
  font-size: 25px;
  position: relative;
  top: -2px;
}

.blue-btn-with-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 25px;
  gap: 10px;
  background: #1480f3;
  border: none;
  outline: none;
  border-radius: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  // transition: 0.25s all ease-in-out;
}

.blue-btn-with-text:hover{
  background: #FFFFFF;
  color: #010101;
  outline: #1480f3;
  border: 1px solid #1480f3;
  cursor: pointer;

}

@media(min-width:320px) and (max-width:767px)
{
  .MuiBreadcrumbs-separator {
    color: #ffffff;
    font-size: 15px;
    position: relative;
    top: 2px;
  }
}