.banner-container-main {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.banner-container {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../../Assets/images/about001.png");
}
.banner-info-container {
  max-width: 80%;
  margin: auto;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
}
.banner-top-text {
  position: relative;
  font-family: "Poppins";
  letter-spacing: 2px;
  color: #fff;
  text-transform: uppercase;
}
.banner-bottom-text,
.banner-middle-text {
  text-transform: var(--transform);
  font-family: "Poppins";
  color: #fff;
}
.banner-middle-text {
  letter-spacing: 2px;
  font-weight: 800;
  color: #fff;
}
.banner-bottom-text {
  letter-spacing: 1.5px;
}
.banner-bottom-break-text,
.blog-bottom-text,
.last-break-text {
  font-family: "Poppins";
  letter-spacing: 1.05px;
  color: #fff;
}
.pagination-container{
  bottom: 20px !important;
  position: absolute;
}