.client-reviews-container {
  margin-top: -70px;
}

.client-reviews-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
  color: #000000;
}

.client-reviews-para {
  font-family: 'Poppins';
  font-style: normal;
  width:70%;
 margin: auto;
 padding-top: 10px;
 text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  opacity: 0.5;
}

.swiper,
swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0px 45px 0px !important;
  z-index: 1;
  display: block;
  height: 400px;
}
// .swiper-slide{
//   padding: 10px;

// }   


.reviews-cards {
  height: 340px;
  padding: 40px 30px;
  position: relative;
  background: #FFFFFF;
  margin-top: 20px;
  box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.reviews-cards-section1 {
  display: flex;
  flex-direction: row;
}

.reviews-cards-section1-right {
  padding-left: 15px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.reviews-client-name {
  margin-bottom: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1480F3;

}

.reviews-client-title {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ACACAC;

}

.reviews-client-review {
  padding-top: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #39374D;

}

.reviews-client-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #696969;
  height: 100px;
  overflow-y: auto;
}

.reviews-cards-icon {
  position: absolute;
  right: 15px;
  bottom: 10px;
}


@media screen and (max-width: 768px) {
  .client-reviews {
    padding: 0px 0px 0px 0px !important;
  }
}

@media (min-width:320px) and (max-width:767px)
{
  .client-reviews-container {
    margin-top: 90px;
  }
  .client-reviews-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 63px;
    text-align: center;
    color: #000000;
}
.client-reviews-para {
  font-family: "Poppins";
  font-style: normal;
  width: 90%;
  margin: auto;
  padding-top: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 36px;
  color: #000000;
  opacity: 0.5;
}
}
@media screen and (max-width: 320px) {
  .reviews-cards {
    padding:40px 15px!important;
  }
}
// @media screen and (max-width: 1024px){
//   .client-reviews-container{
//     margin-top: 190px;
//   }
// }
@media screen and (min-width: 768px) and (max-width:1023px){
  .client-reviews-container{
    margin-top: 60px;
  }
}
// @media screen and (max-width: 425px){
//   .client-reviews-container{
//     margin-top: 890px;
//   }
// }

@media (min-width:1024px) 
{
  .client-reviews-container {
    margin-top: 220px;
  }
  
}