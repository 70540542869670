.nav {
  background-color: transparent;
  color: #177ffa;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0;
  margin: 40px;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0 20px;
  list-style: none;
  display: flex;
  gap: 1rem;
  float: right;
}

.nav li {
  font-size: 24px;
  margin: 0 10px;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  // margin-left: 200px;
}
.header-image {
  width: 359px;
  height: 85px;
}

.hamburger {
  width: 40px;
  margin: 40px;
  cursor: pointer;

  .bar {
    height: 4px;
    width: 0;
    // background: #000;
    background: white;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: 0.3s;

    &.one {
      width: 100%;
    }
    &.two {
      width: 75%;
    }
    &.three {
      width: 50%;
    }
  }
}

.hamburger:hover .bar {
  width: 100%;
  background: white !important;
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  .header-image {
    width: 154px;
    height: 45px;
    margin-left: 5px !important;
    margin-top: 10px;
  }
  .nav {
    margin: 5px 0 0 0;
  }
  .nav ul {
    padding: 0;
    margin-right: 10px;
    list-style: none;
    display: flex;
    gap: 1rem;
    float: right;
}
  .hamburger {
    margin: 0;
    width: 32px;
    .bar {
      height: 3px;
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .header-image {
    width: 157px;
    height: 42px;
    margin-left: 5px !important;
  }
  .nav {
    margin: 10px 0 0 0;
  }
  .hamburger {
    margin: 0;
    width: 32px;
    .bar {
      height: 3px;
    }
  }
}
@media only screen and (min-width: 425px) and (max-width: 600px) {
  .header-image {
    width: 180px;
    height: 50px;
  }
  .hamburger {
    margin: 0;
    width: 32px;
    .bar {
      height: 4px;
    }
  }
  .nav {
    background-color: transparent;
    color: #177ffa;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0;
    margin: 14px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .header-image {
    width: 250px;
    height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .header-image {
    width: 250px;
    height: 60px;
  }
  .hamburger {
    margin: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2560) {
  .hamburger {
    margin: 0;
  }
}

@media only screen and (min-width: 2560px) {
}
